import Vue from 'vue'
import Router from 'vue-router'
import TopicList from '@/components/TopicList.vue'
import TopicDetail from '@/components/TopicDetail.vue'
import TopicEditor from '@/components/TopicEditor.vue'
import TopicMap from '@/components/TopicMap.vue'
import UserCenter from '@/components/UserCenter.vue'
import Info from '@/components/User/Info.vue'
import Login from '@/components/Login/Login.vue'
import Reg from '@/components/Login/Register.vue'
import Wechat from '@/components/Login/Wechat.vue'
import Logout from '@/components/Login/Logout.vue'
import Redirect from '@/components/Login/Redirect.vue'
Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'TopicList',
            component: TopicList 
        },
        {
            path: '/editor',
            name: 'TopicEditor',
            component: TopicEditor
        },
        {
            path: '/map',
            name: 'TopicMap',
            component: TopicMap
        },
        {
            path: '/uc',
            name: 'UserCenter',
            component: UserCenter
        },
        {
            path: '/test',
            name: 'UserCenter',
            component: UserCenter
        },
        {
            path: '/redirect',
            name: 'Redirect',
            component: Redirect
        },
        {
            path: '/info',
            name: 'Info',
            component: Info
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/wechat',
            name: 'Wechat',
            component: Wechat
        },
        {
            path: '/reg',
            name: 'Reg',
            component: Reg
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout
        },
        {
            path: '/:topicId',
            name: 'TopicDetail',
            component: TopicDetail 
        }
    ]
})
