// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import {post, BACKEND_API_PREFIX} from '@/util/post'
import '@/util/use'
import store from '@/util/store'

Vue.prototype.BACKEND_API_PREFIX = BACKEND_API_PREFIX  
Vue.prototype.post = post

/* eslint-disable no-new */
new Vue({
    el: '#app',
    post,
    store,
    router,
    render: h => h(App)
}).$mount("#app")
