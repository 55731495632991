<template>
    <a-col :span="24" style="height: 100%; padding-right: 8px; background-color: white;">
        <a-form style="z-index: 1;"> 
            <a-row >
              <a-form-item required>
                  <a-col span="4" style="text-align: right; ">
                        <span style="color: red;">* <span style="color: black;" required>用户名：</span></span>
                  </a-col>

                  <a-col span="20">
                        <a-row>
                            <a-col :span="16" required>
                                <a-input placeholder="请输入用户名" v-model='user.username' rules="[{ required: false, message: '请输入用户名'}]">
                                <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,0.25)"/>
                                </a-input>
                            </a-col>
                            <a-col  :span="8" style="text-align: center;" >
                                <a-button type="primary" @click="verifyModal" block>
                                  修改密码
                                </a-button>
                            </a-col>
                        </a-row>
                  </a-col>
              </a-form-item>

              <a-form-item>
                <a-row > 
                  <a-col :xs="11" :sm="12" :md="12" :lg="12" :xl="12">
                       <p style="text-align: center; margin-bottom: 0px;">用户图像&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  </a-col>
                  <a-col :xs="11" :sm="12" :md="12" :lg="12" :xl="12" style="text-align: center;">
                      <p style="text-align: center; margin-bottom: 0px;">扫码设置微信绑定&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  </a-col>
                </a-row>

                <a-row type="flex"> 
                    <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" style="text-align: center; ">
                            <a-upload
                              name="avatar"
                              :showUploadList="false"
                              action=""
                              :beforeUpload="beforeUpload"
                              @change="handleProfilePic">
                                <a-avatar shape="square" :size="152"  v-if="user.img_profile_url" 
                                    :src="user.img_profile_url" alt="avatar"  style="border: 1px solid #E2E2E2;"/>
                                <div v-else>
                                      <a-avatar shape="square" :size="152" icon="user" :type="loading ? 'loading' : 'plus'" style="border: 1px solid #E2E2E2;"/>
                                </div>
                            </a-upload>
                    </a-col>

                    <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12"style="text-align: center; ">
                        <div id="qwe" desc="for placing wechat qrcode">
                        </div>
                    </a-col>
                </a-row>
              </a-form-item>

              <a-col span="4" style="text-align: right; ">
                  <a-form-item required >
                      <span style="color: black;" required>姓名：</span>
                  </a-form-item>
                  <a-form-item required>
                      <span style="color: red;">* <span style="color: black;" required>手机号：</span></span>
                  </a-form-item>
                  <a-form-item  required>
                      <span style="color: black;" required>邮箱：</span>
                  </a-form-item>
                  <a-form-item  required>
                      <span style="color: black;" required>地址：</span>
                  </a-form-item>
                  <a-form-item  required>
                      <span style="color: black;" required>单位：</span>
                  </a-form-item>
                  <a-form-item  required>
                      <span style="color: black;" required>职位：</span>
                  </a-form-item>
              </a-col>

              <a-col span="20">
                  <a-form-item :wrapper-col="{ span: 24 }">
                            <a-input placeholder="请输入姓名" v-model='user.nickname' rules="[{ required: false, message: '请输入姓名'}]">
                            <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,0.25)"/>
                            </a-input>
                  </a-form-item>
                  <a-form-item required>
                    <a-row>
                      <a-col :span="16" required>
                            <a-input placeholder="输入手机号码" v-model='user.phone' rules="[{ required: true, message: '请输入手机号码' }]">
                                <a-icon slot="prefix" type="phone" />
                            </a-input>
                      </a-col>
                      <a-col  :span="8" style="text-align: center;" >
                          <a-button type="primary" @click="phoneModal" block>换个号</a-button>
                      </a-col>
                    </a-row>
                  </a-form-item>

                  <a-form-item  :wrapper-col="{ span: 24}">
                            <a-input type="email" placeholder="请输入您的邮箱" v-model='user.email'>
                                <a-icon slot="prefix" type="mail" style="color: rgba(0,0,0,0.25)"/>
                            </a-input>
                  </a-form-item >

                  <a-form-item :wrapper-col="{ span: 24 }">
                            <a-input type="address" placeholder="请输入您的地址" v-model='user.address'>
                                <a-icon slot="prefix" type="environment" style="color: rgba(0,0,0,0.25)"/>
                            </a-input>
                  </a-form-item>
                  <a-form-item :wrapper-col="{ span: 24 }">
                            <a-input type="affiliation"  placeholder="请输入公司或组织名称" v-model='user.affiliation'>
                                <a-icon slot="prefix" type="bank" style="color: rgba(0,0,0,0.25)"/>
                            </a-input>
                  </a-form-item>
                  <a-form-item :wrapper-col="{ span: 24 }">
                            <a-input type="jobtitle"  placeholder="请输入您在公司或组织的职位" v-model='user.job'>
                                <a-icon slot="prefix" type="team" style="color: rgba(0,0,0,0.25)"/>
                            </a-input>
                  </a-form-item>

                  <a-form-item :wrapper-col="{ span: 24}" style="margin-bottom: 0px;">
                    <a-row >
                      <a-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="text-align: center;">
                          <a-button type="primary" @click="infoUpdate">
                           保存修改
                          </a-button> 
                      </a-col>
                    </a-row>
                  </a-form-item>
              </a-col>
            </a-row>

            <a-modal title="请验证您的密码" v-model="verify_panel" @cancel="handleVerify" :footer="null">
                  <a-form-item style="margin-bottom: 0px">
                      <a-row :span="24">
                          <a-col :span="18" :offset="3">
                              <a-input type="password" placeholder="请输入您的原始密码" 
                              v-model='user.cfm_password'
                               v-on:change="verifyPassword" 
                              >
                                  <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,0.25)"/>
                              </a-input>
                              <a-alert v-if="verify_confirm  != ''" :message="verify_confirm" type="error"/>
                          </a-col>
                      </a-row>
                      <a-row>
                          <a-col :span="21">
                              <a-button type="primary" @click="passwordModal" :disabled='verify_button' style="float: right;">继续</a-button>
                          </a-col>
                      </a-row>
                  </a-form-item>
            </a-modal>

            <a-modal title="修改密码" v-model="password_panel" @cancel="handlePassword" :footer="null">
                <a-form-item style="margin-bottom: 0px">
                  <a-row :span="24">
                    <a-col :span="18" :offset="3">
                        <a-input type="password" 
                        placeholder="请输入您的新密码" 
                        v-model='user.new_password'>
                            <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,0.25)"/>
                        </a-input>
                    </a-col>
                  </a-row>
                </a-form-item>
                <a-form-item style="margin-bottom: 0px">
                  <a-row :span="24">
                    <a-col :span="18" :offset="3">
                        <a-input id='conpassword' type="password" 
                        placeholder="请重复输入您的新密码" 
                        v-model='user.cfm_new_password' 
                        v-on:change="checkNewPasswordMatch()">
                            <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,0.25)"/>
                        </a-input>
                        <a-alert v-if="confirm  != ''" :message="confirm" type="error"/>
                    </a-col>
                  </a-row>
                </a-form-item>
          </a-modal>

          <a-modal title="更换手机号" v-model="phone_panel" @cancel="handlePhone" :footer="null">
            <a-form style="padding: 0px 30px">
                <a-form-item style="margin-bottom: 0px">
                  <a-row>
                    <a-col :span="24">
                        <a-input placeholder="请输入手机号" v-model='user.new_phone' rules="[{ required: true, message: '请输入手机号码' }]">
                            <a-icon slot="prefix" type="phone" />
                        </a-input>
                    </a-col>
                  </a-row>
                </a-form-item>
                <a-form-item style="margin-bottom: 0px">
                    <a-row>
                        <a-col :span="13">
                            <a-input placeholder="验证码" v-model='user.valcode' v-on:change="verifyCode()" rules="[{ required: true, message: '请输入验证码' }]">
                            </a-input>
                            <a-alert v-if="verify_phone  != ''" :message="verify_phone" type="error"/>
                        </a-col>
                        <a-col :span="11">
                            <a-button type="primary" @click="sendSMS" block>发送验证码</a-button>
                        </a-col>
                    </a-row>
                </a-form-item>
            </a-form>
          </a-modal>

        </a-form>
    </a-col>
</template>

<script>
import reqwest from 'reqwest'
import UserMenu from '@/components/UserMenu.vue'
//import {BACKEND_API_PREFIX} from '@/util/config'
const BACKEND_API_PREFIX = "";

function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: "Info",
  components: {
      UserMenu
  },
  data() {
    return {
      tab: "uc,info",
      loading: false,
      password_panel: false,
      phone_panel: false,
      verify_panel: false,
      verify_button: '',
      confirm: '',
      verify_confirm: '',
      verify_phone: '',
      user:{}
    }
  },

    mounted(){

        let b = document;
        let d = b.createElement("iframe");
        let appid = "wxd525f3df2d19dda8";
        let redirect_uri = "https%3a%2f%2fwww.alonger.cn%2fwechat%2fbind";
        //let self_redirect = "true";
        let scope = "snsapi_login";
        let href = "https://www.alonger.cn/static/css/qruinfo.css"
        
        let state = this.$store.getters.getId();
        let e = "https://open.weixin.qq.com/connect/qrconnect?appid="
                + appid + "&redirect_uri="
                + redirect_uri + "&response_type=code&scope="
                + scope + "&state="
                + state + "&href="
                //+ state + "&self_redirect="
                //+ self_redirect + "&href="
                + href + "#wechat_redirect";

        d.src=e,
        d.frameBorder="0",
        d.allowTransparency="true",
        d.scrolling="no",
        d.width="152px",
        d.height="152px";
        var f=b.getElementById('qwe');
        f.innerHTML="",
        f.appendChild(d);
  },

  methods: {
    sendSMS: function() {
        let phone = this.user.new_phone;
        if (!phone) {
           this.$message.info("请输入手机号码");
           return;
        }
        if (phone.split("").length != 11) {
            this.$message.info("手机号码错误");
            return;
        }
        try{
            let d = parseInt(phone);
        } catch(e) {
            this.$message.info("手机号码错误");
            return;
        }
        let postdata = {
            phone: phone
        }
        let that = this;
        that.post('/sms/login', postdata)
         .then(function (data) {
             if (data.ret == 200) {
                 that.val = data.data.code;
                 that.$message.success("验证码发送成功");
             } else {
                 that.$message.error("验证码发送失败，请重新发送!");
             }
         }).catch(function (e) {
           alert(e);
         });

    },
    verifyCode: function() {
        let valcode = this.user.valcode;
        valcode = this.$crypto.SHA256(valcode).toString();
        console.log(this.val)
        console.log(valcode)
        if (this.val == valcode) {
          this.user.phone = this.user.new_phone
          this.verify_phone = "";
          this.phone_panel = false 

          const formData = new FormData();   

          if (this.user.phone) 
            formData.append('phone', this.user.phone)

            //formData.append('id', this.user.id)
            formData.append('task', 'UPDATE_USER_INFO')
            formData.append('agent_key', this.$store.getters.getAgentkey())
            formData.append('access_token', this.$store.getters.getToken())

            let that = this;
            reqwest({
                url: BACKEND_API_PREFIX + 'user',
                method: 'post',
                processData: false,
                data: formData,

                success: (resp) => {
                    
                  let rsp = JSON.parse(resp);
                  console.log(rsp.ret)
                  if (rsp.ret == 200){
                    that.$message.success('更新成功.');
                  } else{
                    that.$message.error(rsp.msg);
                  }
                }, error: () => {
                  that.uploading = false
                  that.$message.error('更新失败.');
                }
              })    
        } 
        else {
            
            this.verify_phone = "验证码错误";
        }
    },
    verifyPassword: function() {

        let password = this.user.password;
        let cfm_password = this.user.cfm_password;
        cfm_password  = this.$crypto.SHA1(cfm_password).toString();
        console.log("password",password)
        console.log("cfm_password",cfm_password)
        if (password == cfm_password) {
          this.$message.success("ok"); 
          this.verify_confirm = ""; 
          this.verify_button= false       
        } 
        else {
          this.verify_confirm = "两次输入密码匹配";
          this.verify_button= true

        }
    },

    checkNewPasswordMatch: function() {

        if (this.user.new_password == this.user.cfm_new_password) {
          let new_password  = this.$crypto.SHA1(this.user.new_password).toString();
          this.user.password = new_password;
          this.confirm = "";
          this.password_panel = false  

          const formData = new FormData();   

          if (this.user.password) 
            formData.append('password', this.user.password) 

            //formData.append('id', this.user.id)
            formData.append('task', 'UPDATE_USER_INFO')
            formData.append('agent_key', this.$store.getters.getAgentkey())
            formData.append('access_token', this.$store.getters.getToken())

            let that = this;
            reqwest({
                url: BACKEND_API_PREFIX + 'user',
                method: 'post',
                processData: false,
                data: formData,

                success: (resp) => {
                    
                  let rsp = JSON.parse(resp);
                  console.log(rsp.ret)
                  if (rsp.ret == 200){
                    that.$message.success('更新成功.');
                  } else{
                    that.$message.error(rsp.msg);
                  }
                }, error: () => {
                  that.uploading = false
                  that.$message.error('更新失败.');
                }
              })    
        } 
        else {
          this.confirm = "两次输入密码不匹配";
        }
    },

    infoUpdate: function() {

        const formData = new FormData();
        if (this.user.phone) 
          formData.append('phone', this.user.phone)
        
        if (this.user.username) 
          formData.append('username', this.user.username)

        if (this.user.nickname) 
          formData.append('nickname', this.user.nickname)
        if (this.user.email) 
          formData.append('email', this.user.email)
          console.log('formData..',this.user.email)
        if (this.user.address) 
          formData.append('address', this.user.address)
        if (this.user.affiliation) 
          formData.append('affiliation', this.user.affiliation)
        if (this.user.job) 
          formData.append('job', this.user.job)

        if (this.user.profile_pic) {
          let profile_pic = this.user.profile_pic;
          let fileList =[profile_pic]
          console.log('formData_fileList_pic:',fileList)

          
          formData.append('files[]', this.user.profile_pic); 
          }
          
          console.log('formData_profile_pic:',this.user.profile_pic)


        formData.append('task', 'UPDATE_USER_INFO')
        formData.append('agent_key', this.$store.getters.getAgentkey())
        formData.append('access_token', this.$store.getters.getToken())

        let that = this;
      reqwest({
        url: BACKEND_API_PREFIX + 'user',
        method: 'post',
        processData: false,
        data: formData,

        success: (resp) => {
          that.fileList = []
          that.uploading = false
            
          let rsp = JSON.parse(resp);
          console.log(rsp.ret)
          if (rsp.ret == 200){
            that.$message.success('更新成功.');
          } else{
            that.$message.error(rsp.msg);
          }
        }, error: () => {
          that.uploading = false
          that.$message.error('更新失败.');
        }
      })
    },
    passwordModal() {

      this.verify_panel = false
      this.password_panel = true
    },
    phoneModal() {
      this.phone_panel = true
    },
    verifyModal() {
      if(!this.user.password){
        this.password_panel = true
        this.verify_panel = false

      }
      else{this.verify_panel = true}
    },
    handlePassword(e) {
      console.log('Clicked cancel button');
      this.password_panel = false
    },
    handlePhone(e) {
      console.log('Clicked cancel button');
      this.phone_panel = false
    },
    handleVerify(e) {
      console.log('Clicked cancel button');
      this.verify_panel = false
    },

    handleProfilePic (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      getBase64(info.file.originFileObj, (imageUrl) => {
          this.user.img_profile_url = imageUrl
          this.loading = false
                  })
      if (info.file.status === 'done') {
        // Get this url from response in real world.

      }
    },
    beforeUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 0.512
      this.user.profile_pic = file;
      if (!isLt2M) {
        this.$message.error('图片的应小于512KB!')
      }
      return isLt2M
    },

  },
  created() {
      this.$store.commit('changetab', this.tab)
      let that = this;
      let agent_key = that.$store.getters.getAgentkey();
      let access_token = that.$store.getters.getToken();
      let postdata = {
          'task':'GET_USER_INFO',
          agent_key: agent_key,
          access_token: access_token
      };
      that.post('user', postdata).then(function(data){
          if(data.ret ==200){
              that.user=data.data;
          } else {
              that.$roter.push("/");
          }
      }).catch(function(e){
            console.log(e);
            that.$roter.push("/");
      });

    },
  }
</script>

<style scpoed>
    .user-menu {
        position: fixed; 
        top: 20px; 
        right: 40px;
        z-index: 3 
    }
    div /deep/ .ant-form-item { 
        margin-bottom: 6px;
    }
    .impowerBox .qrcode {width: 200px;}
    .impowerBox .title {display: none;}
    .impowerBox .info {width: 200px;}
    .status_icon {display: none}
    .impowerBox .status {text-align: center;}
</style>
