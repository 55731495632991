<template>
    <div :style="sampleHeight">
        <loading :active.sync="loading" :can-cancel="true" color="rgb(47, 185, 248)" :opacity="0.1" :is-full-page="true"/>
            <div :style="sampleArticleList">
                <a-list
                    class="demo-loadmore-list"
                    item-layout="horizontal"
                    :data-source="topicOldSample"
                    key = "refreshSample"
                >
                    <a-list-item slot="renderItem" slot-scope="item, index" v-if="!item.delete" key="refreshSample">
                        <a-list-item-meta description="" v-if="!item.delete" @mouseenter="showTooltip(index)" @mouseleave="hideTooltip(index)">
                            <a-row slot="title" type="flex" align="top" style="position:relative;" v-if="!item.delete">
                                <a-tooltip placement="top" v-if="item.showTooltip">
                                    <template slot="title">
                                        <span>删除</span>
                                    </template>
                                    <a-popconfirm title="你确定删除当前文件吗？"
                                        placement="left" ok-text="确认" cancel-text="取消" 
                                        @confirm="deleteSample(item)" 
                                        @cancel=""
                                    >
                                        <div class="deleteSample"  @click="showTipStill(index)"></div>
                                    </a-popconfirm>
                                </a-tooltip>
                                <a-tooltip placement="top" v-if="item.showTooltip">
                                    <template slot="title">
                                        <span>查找相似文档</span>
                                    </template>
                                    <div class="findSample" @click = "findSample(index,item)"></div>
                                </a-tooltip>
                                <p v-if = "item.u_tag" :class="'sampleTagArticle sampleArticle topic-' + theme + '-theme-item-title'">
                                    {{item.content}}
                                </p>
                                <p v-else-if = "!item.u_tag" :class="'sampleArticle topic-' + theme + '-theme-item-title'" >{{item.content}}</p>
                            </a-row>
                        </a-list-item-meta>
                        <div v-for="(sample,order) in item.newSample" style="width:100%;" 
                            @mouseenter = "changeCurrentSample(sample,index,order)"
                            @mouseleave = "hideSampleTooltip(index,order)"
                        >
                            <div class="newSampleFunction">
                                <a-tree-select ref="input" :tree-data="topicTree"
                                    v-if = "sample.showTooltip"
                                    placeholder="添加为其他类"
                                    @click = "showSampleTooltipStill(index,order)"
                                    @change="treeSelectChange"
                                    size="small" 
                                    class="treeSelect"
                                    tree-default-expand-all
                                    :class="'topic-' + theme + '-theme-input'"
                                >
                                </a-tree-select>
                                <a-tooltip placement="top" v-if = "sample.showTooltip">
                                    <template slot="title">
                                        <span>添加为当前类</span>
                                    </template>
                                    <div class="becomeSample" @click="addNewSample"></div>
                                </a-tooltip>
                                <p :class="'newSampleInfo topic-' + theme + '-theme-item-title'" style="margin-left:5%;">
                                    数据来源：{{sample.data_source_name}}
                                </p>
                                <p :class="'newSampleInfo topic-' + theme + '-theme-item-title'">
                                    相似度：{{sample.score}}
                                </p>
                            </div>
                            <div class="newSampleContent">
                                <p :class="'newSample topic-' + theme + '-theme-item-title'">
                                    {{sample.content}}
                                </p>
                            </div>
                        </div>
                        <a-row v-if="!noMoreNewSample && !loading && item.newSample" style="padding: 8px;">
                            <a-button type="primary" @click="findSample(index,item)" >加载更多相似文档</a-button>
                        </a-row>
                        <a-row v-if="noMoreNewSample" style="border-top: 1px solid #dcdcdc;">
                            <h2 style="color: rgba(128, 128, 128, 0.65); font-size: 16px; padding: 24px 0px;">没有更多数据了</h2>
                        </a-row>
                        <a-row><div :class="'cutOff topic-' + theme + '-theme-body'"></div></a-row>
                    </a-list-item>
                </a-list>
                <a-row v-if="noMoreRecords" style="border-top: 1px solid #dcdcdc;">
                    <h2 style="color: rgba(128, 128, 128, 0.65); font-size: 16px; padding: 24px 0px;">没有更多数据了</h2>
                </a-row>
                <a-row v-if="!noMoreRecords && !loading" style="padding: 8px;">
                    <a-button type="primary" @click="getNextPage()">加载更多</a-button>
                </a-row>
            </div>
    </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default({
    name: "topic-sample",
    components: {
        Loading
    },
    data(){
        return{
            topicOldSample:[],
            newSample:[],
            sampleHeight:0,
            page: 1,
            pageSize: 10,
            noMoreRecords: false,
            noMoreNewSample: false,
            loading: false,
            dataList:[],
            deleteList:[],  
            deleteCount: 0,
            currentIndex : -1,
            refreshSample : 0,
            currentSample: {},
            topicText: "",
            currentSampleOrder: -1,
            currentSampleIndex: -1,
            theme: ""
        }
    },
    props:{

        mapId:{
            type: String,
            default: function(){
                return "";
            }
        },
        
        refreshSampleId: {
            type: Number,
            default: function() {
                return 0;
            }
        },

        height: {
            type: Number,
            default: function() {
                return 0;
            }
        },

        width: {
            type: Number,
            default: function() {
                return 0;
            }
        },

        topicMind:{
            type: Object,
            default: function(){
                return {};
            }
        },

        clusterId:{
            type: String,
            default: function(){
                return "";
            }
        },

        topicTree: {
            type: Array,
            default: function () {
                return []
            }
        }

    },
    watch:{

        refreshSampleId: function(){
            this.clusterId = ""
            this.page = 1
            this.deleteCount = 0
            this.currentIndex = -1
            this.getOldSample()
        },

        clusterId: function(){
            this.page = 1
            this.currentIndex = -1
            this.deleteCount = 0
            this.getOldSample()
        }

    },
    methods:{

        showTooltip: function(index) {
            if (this.currentIndex >= 0) {
                this.$set(this.topicOldSample[this.currentIndex], "showDel", false);
                this.$set(this.topicOldSample[this.currentIndex], "showTooltip", false);
            }
            this.$set(this.topicOldSample[index], "showTooltip", true);
            this.currentIndex = index;
        },

        hideTooltip: function(index) {
            if (!this.topicOldSample[index]["showDel"]) {
                this.$set(this.topicOldSample[index], "showTooltip", false);
            }
        },

        showTipStill: function(index) {
            this.$set(this.topicOldSample[index], "showDel", true);
        },

        getNextPage: function(){
            this.page += 1
            this.getOldSample()
        },
        
        findNodeText: function(topicMind,nodeId){
            if(topicMind.id == nodeId){
                this.topicText=topicMind.text
            } 
            if(topicMind.children){
                for(var ch = 0;ch < topicMind.children.length;ch++){
                    this.findNodeText(topicMind.children[ch],nodeId)
                }
            }
        },

        addNewSample: function(sample){
            var nodeId = this.mapId.split("-")[1]
            this.findNodeText(this.topicMind,nodeId)
            let agentKey = this.$store.getters.getAgentkey();
            let postdata = {
                task: "DOC_TAGGING",
                doc_id: this.currentSample.id,
                node_id: this.mapId,
                topic_id: this.mapId.split("-")[0],
                data_source: this.currentSample.data_source,
                agent_key: agentKey,
                tag: this.topicText
            };
            let that = this
            this.post("topic", postdata).then(function(data) {
                if (data.ret == 200){
                    var data = JSON.parse(data.topic)
                    that.$emit("changeTopicMind",data)
                }
            })
        },

        getOldSample: function(){
            this.loading = true
            this.deleteCount = this.deleteList.length
            var fromNum = 0
            var toNum = 0
            if(this.deleteCount){
                fromNum = (this.page - 1) * this.pageSize-this.deleteCount+1,
                toNum = this.page * this.pageSize-this.deleteCount+1
            }
            else{
                fromNum = (this.page - 1) * this.pageSize,
                toNum = this.page * this.pageSize
            }

            if(this.clusterId ==""){
                var postData = {
                    task: "GET_UPLOAD_DATA",
                    uid: this.mapId,
                    from: fromNum,
                    to: toNum
                }
            }
            else{
                var postData = {
                    task: "GET_UPLOAD_CLUSTER_DOCS",
                    uid: this.mapId,
                    cluster_id : this.clusterId,
                    from: fromNum,
                    to: toNum
                }
            }
            let that = this
            this.post("topic",postData).then(function(data){
                if (data && data.ret == 200 && data.msg == "ok"){
                    console.log(data)
                    if (data.data.length == 0 && that.page > 1) {
                        that.noMoreRecords = true;
                    } 
                    else if (data.data.length == 0 && that.page == 1) {
                        that.topicOldSample = []
                        that.noMoreRecords = true;
                        that.$message.warning("没有更多数据了！");
                    }
                    else{
                        if (that.page==1){
                            that.topicOldSample=[]
                            
                            for (var i = 0; i < data.data.length; i++) {
                                that.topicOldSample.push(data.data[i]);
                            }
                        }
                        else{
                            for(var i = 0; i < data.data.length; i++){
                                that.topicOldSample.push(data.data[i]);
                            }
                        }
                        if(data.data.length<9){
                            that.noMoreRecords = true; 
                        }
                        else{
                            that.noMoreRecords = false;
                        }
                    }
                    that.deleteList = []
                    that.loading = false
                    console.log("topicOldSample")
                    console.log(that.topicOldSample)
                }
            })
        },
            
        findSample: function(index,item){
            var page = 0
            if(!item.page){
                page = 1
            }
            else{
                page = item.page 
            }
            var content = item.content
            var newSampleArray = []
            if(!item.newSample){
                newSampleArray = []
            }
            else{
                newSampleArray = item.newSample
            }
            var topicId = this.mapId.split("-")[0]
            var postData = {
                "task" : "GET_SIM_DOCS",
                "content" : content,
                "topic_id" : topicId,
                "from": (page - 1) * this.pageSize,
                "to": page * this.pageSize
            }
            let that = this
            this.loading = true

            this.post("topic",postData).then(function(data){
                if (data && data.ret == 200 && data.msg == "ok"){
                    that.currentSampleOrder = -1
                    that.currentSampleIndex = -1
                    that.currentIndex = -1
                    if (data.data.length == 0 && page > 1) {
                        that.noMoreNewSample = true;
                    } 
                    else if (data.data.length == 0 && page == 1) {
                        that.noMoreNewSample = true;
                        that.$message.warning("没有更多数据了！");
                    } 
                    else{
                        if (that.page==1){
                            that.newSample=[]
                            for (var i = 0; i < data.data.length; i++) {
                                newSampleArray.push(data.data[i]);
                            }
                        }
                        else{
                            for(var i = 0; i < data.data.length; i++){
                                newSampleArray.push(data.data[i]);
                            }
                        }
                        that.noMoreNewSample = false; 
                    }
                    that.loading = false
                    page += 1
                    that.$set(that.topicOldSample[index], "newSample",newSampleArray)
                    that.$set(that.topicOldSample[index], "page",page)
                }
            })
        },

        hideSampleTooltip: function(index,order){
            if(!this.topicOldSample[index].newSample[order].showInput){
                this.$set(this.topicOldSample[index].newSample[order], "showTooltip",false)
            }
        },

        changeCurrentSample: function(sample,index,order){
            if(this.currentSampleIndex == index){
                this.currentSampleOrder == order
            }
            else{
                this.currentSampleOrder == -1

            }
            if(this.currentSampleOrder >= 0){
                this.$set(this.topicOldSample[index].newSample[this.currentSampleOrder], "showInput",false)
                this.$set(this.topicOldSample[index].newSample[this.currentSampleOrder], "showTooltip",false)
            }
            this.$set(this.topicOldSample[index].newSample[order], "showTooltip",true)
            this.currentSampleOrder = order
            this.currentSample = sample
            this.currentSampleIndex = index
        },

        showSampleTooltipStill: function(index,order){
            this.$set(this.topicOldSample[index].newSample[order], "showInput",true)
        },

        treeSelectChange: function(value, label, extra) {
            let tag = label[0]
            let agentKey = this.$store.getters.getAgentkey();
            let postdata = {
                task: "DOC_TAGGING",
                doc_id: this.currentSample.id,
                node_id: value,
                topic_id: this.mapId.split("-")[0],
                data_source: this.currentSample.data_source,
                agent_key: agentKey,
                tag: tag
            };
            let that = this;
            that.post("topic", postdata).then(function(data) {
                if (data.ret == 200) {
                    var data = JSON.parse(data.topic)
                    that.$emit("changeTopicMind",data)
                    } else {
                        that.$message.error("标签添加失败，请重试...");
                    }
                }).catch(function(e) {
                    console.log(e);
                    that.$message.error("标签添加失败，请重试...");
                });
                
        },
 
        changeDeleteMode: function(){
            if(this.deleteMode){
                this.deleteMode = false
            }
            else{
                this.deleteMode = true
            }
        },

        deleteSample: function(e){
            var dataSource = ""
            if(e.data_source){
                dataSource = e.data_source.value
            }
            else{
                dataSource = "uploaddata"
            }
            this.dataList = []
            this.dataList.push(e.id)
            this.deleteList.push(e.id)
            this.loading = true
            this.deleteMode = false
            var topicId = this.mapId.split("-")[0]
            var nodeId =  this.mapId.split("-")[1]
            let postData={
                "topic_id": topicId,
                "node_id": nodeId,
                "uid":this.mapId,
                "dataList": this.dataList,
                "task": "DEL_UPLOAD_DATA",
                "topic": this.topicMind,
                "data_source": dataSource
            }
            let that = this
            this.post("topic",postData).then(function(data){
                if (data && data.ret == 200 && data.msg == "ok"){
                    for(var i=0;i<that.topicOldSample.length;i++){
                        for(var d=0;d<that.dataList.length;d++){
                            if(that.topicOldSample[i].id == that.dataList[d]){
                                that.$set(that.topicOldSample[i], "delete", true)
                            }
                        }   
                    }
                    var data = JSON.parse(data.data)
                    that.$emit("changeTopicMind",data)
                    that.deleteCount = that.dataList.length
                }
                that.dataList = []
                that.selectAll = true
                that.loading = false
                that.currentIndex = -1
            })

        }
    },

    created(){
        var theme = localStorage.getItem("theme");
        if (!theme) {
            theme = "default";
        }
        this.theme = theme;

        this.sampleHeight = {"height": (this.height - 0) + "px"};
        this.sampleArticleList = {"height": (this.height) + "px","overflow-y":"auto"}
    }
})
</script>
<style scoped>

/deep/ .ant-tabs-nav-scroll{
    height: auto;
    width: 100%;
    background: #FFFFFF;
    z-index: 999;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #e8e8e8;
}

/deep/ .ant-spin-container{
    top: 10px;
}

/deep/ .ant-list-split .ant-list-item{
    border-bottom:1px;
    padding: 0px 0px 0px 0px; 
}

/deep/ .ant-list-item{
    display: block;
}

.treeSelect{
    width: 130px;
    float:right;
    margin-right:20px;
    margin-top:22px;
}

.newSampleFunction{
    height:50px;
    width:100%;
}

.newSampleContent{
    height:155px;
    width:100%;
}

.newSampleInfo{
    float:left;
    font-size:20px;
    margin-right:20px;
    margin-top:18px;
    margin-bottom:0px;
}

.sampleArticle{
    width: 100%;
    line-height:1.4;
    text-align: justify;
    height: 150px;
    overflow: auto;
    margin-top: 15px;
    margin-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
}
.sampleTagArticle{
    color:seagreen;
}
.newSample{
    width: 95%;
    text-align: justify;
    float:right;
    height: 150px;
    overflow: auto;
    margin-bottom: 0px;
    padding-right: 20px;
    font-size: 16px;
    line-height: 1.5;
}

p::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
}

.deleteSample{
    height: 24px;
    width: 24px;
    margin-right: 0px;
    top: -10px;
    right: 20px;
    position: absolute;
    z-index: 999;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-repeat: no-repeat; 
    background-size: 100% 100%;
    background-position: center;
    cursor: pointer;
    background-image: url(../assets/img/delete.png);
}

.deleteSample:hover{
    background-image: url(../assets/img/hover/delete.png);
}

.findSample{
    height: 24px;
    width: 24px;
    top: -10px;
    right: 40px;
    margin-right: 8px;
    position: absolute;
    z-index: 999;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-repeat: no-repeat; 
    background-size: 100% 100%;
    background-position: center;
    cursor: pointer;
    background-image: url(../assets/img/findSample.png);
}

.findSample:hover{
    background-image: url(../assets/img/hover/findSample.png);
}

.becomeSample{
    width: 24px;
    height: 24px;
    float: right;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 20px;
    background-size: contain;
    background-repeat: no-repeat; 
    background-size: 100% 100%;
    background-position: center;
    background-image: url(../assets/img/attach.png);
}

.becomeSample:hover{
    background-image: url(../assets/img/hover/attach.png);
}

.cutOff{
    height:5px;
    width:100%;
    margin-top: 12px;
    margin-bottom: 12px;
}

</style>
