import Vue from 'vue'

import CryptoJS from 'crypto-js'
import echarts from 'echarts'
import less from 'less'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'

Vue.use(CryptoJS)
Vue.use(echarts)
Vue.use(less)

Vue.prototype.$crypto = CryptoJS
Vue.prototype.echarts = echarts

import { Button } from 'ant-design-vue'
import { Menu } from 'ant-design-vue'
import { Layout } from 'ant-design-vue'
import { Card } from 'ant-design-vue'
import { Form } from 'ant-design-vue'
import { Input } from 'ant-design-vue'
import { Icon } from 'ant-design-vue'
import { Row } from 'ant-design-vue'
import { Col } from 'ant-design-vue'
import { Alert } from 'ant-design-vue'
import { Dropdown } from 'ant-design-vue'
import { Collapse } from 'ant-design-vue'
import { Cascader } from 'ant-design-vue'
import { Select } from 'ant-design-vue'
import { Pagination } from 'ant-design-vue'
import { Comment } from 'ant-design-vue'
import { Spin } from 'ant-design-vue'
import { Rate } from 'ant-design-vue'
import { Checkbox } from 'ant-design-vue'
import { Breadcrumb } from 'ant-design-vue'
import { Modal } from 'ant-design-vue'
import { Drawer } from 'ant-design-vue'
import { Tabs } from 'ant-design-vue'
import { Tag } from 'ant-design-vue'
import { Badge } from 'ant-design-vue'
import { Progress } from 'ant-design-vue'
import { DatePicker } from 'ant-design-vue'
import { message } from 'ant-design-vue'
import { Affix } from 'ant-design-vue'
import { Steps } from 'ant-design-vue'
import { Skeleton } from 'ant-design-vue'
import { Upload } from 'ant-design-vue'
import { Popconfirm } from 'ant-design-vue'
import { Divider } from 'ant-design-vue'
import { Avatar } from 'ant-design-vue'
import { Tooltip } from 'ant-design-vue'
import { Radio } from 'ant-design-vue'
import { TreeSelect } from 'ant-design-vue'
import { TimePicker } from 'ant-design-vue'
import { List } from 'ant-design-vue'
import { Result } from 'ant-design-vue'
import { ConfigProvider } from 'ant-design-vue'
import { Slider } from 'ant-design-vue'
import { Switch } from 'ant-design-vue'
Vue.use(Slider)
Vue.use(Switch)
Vue.use(Button)
Vue.use(Menu)
Vue.use(Layout)
Vue.use(Card)
Vue.use(Form)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Alert)
Vue.use(Dropdown)
Vue.use(Collapse)
Vue.use(Cascader)
Vue.use(Select)
Vue.use(Pagination)
Vue.use(Comment)
Vue.use(Spin)
Vue.use(Rate)
Vue.use(Checkbox)
Vue.use(Breadcrumb)
Vue.use(Modal)
Vue.use(Drawer)
Vue.use(Tabs)
Vue.use(Tag)
Vue.use(Badge)
Vue.use(DatePicker)
Vue.use(Progress)
Vue.use(message)
Vue.use(Affix)
Vue.use(Steps)
Vue.use(Skeleton)
Vue.use(Upload)
Vue.use(Popconfirm)
Vue.use(Divider)
Vue.use(Avatar)
Vue.use(Tooltip)
Vue.use(Radio)
Vue.use(TreeSelect)
Vue.use(TimePicker)
Vue.use(List)
Vue.use(Result)
Vue.use(ConfigProvider)

Vue.prototype.$message = message

message.config({
  top: `40%`,
  duration: 2,
  maxCount: 3,
});

