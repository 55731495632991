import axios from 'axios'
import store from '@/util/store'
import router from '@/router/index'

const BACKEND_API_PREFIX = "/dev/";

function delay (time) {
  return new Promise((resolve, reject) => {
    setTimeout(function() {
      resolve()
    }, time)
  })
}

let post = async function (url, params) {
  let time = 50
  if (!url) 
    return {}

  if (!url.startsWith("/"))
    url = BACKEND_API_PREFIX + url; 

  for (let i = 0; i < 3; i++) {
    try {
      let data = await trypost(url, params)
      return data
    } catch (e) {
      if (e) 
          console.log(e)
      else
          console.log("Got an empty exception in post"); 

      delay(time * (i + 1))
      continue
    }
  }
  return {}
}


let trypost = function (url, params) {

  let agent_key = localStorage.getItem('agent_key');
  let access_token = localStorage.getItem('access_token');
  let isLogin = localStorage.getItem('login');

  //console.log("try post ", url, agent_key, access_token, isLogin);
  //console.log("try post params ", params);
  params["agent_key"] = agent_key;
  if (url.split("token").length <= 1) {
      if (!agent_key || !isLogin || !access_token) {
          localStorage.clear()
          window.location.href = "/";
      }
  }
  return new Promise((resolve, reject) => {
    axios.post(url, params)
    .then(res => {
      let rest = res.data
      if (rest.ret != 400) {
        resolve(res.data)
      } else {
        reject(rest.msg)
      }

    })
    .catch(err => {
      reject(err)
    })
  })
}

export {post, BACKEND_API_PREFIX}
