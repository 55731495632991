<template>
    <a-dropdown placement="bottomRight" style="margin-top: 8px;">
        <div>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()"> 
                <a-avatar v-if="user.img_profile_url" shape="circle" :size="iconSize" :src="user.img_profile_url"/>
                <a-avatar v-else="" shape="circle" :size="iconSize" icon="user"/>
            </a>
        </div>
        <a-menu slot="overlay" theme="light">
            <a-menu-item>
                <a @click="routeTo('/logout')">
                    <a-icon type="logout" />
                    <span style="margin-left: 4px;">登录注销</span>
                </a>
            </a-menu-item>
        </a-menu>
    </a-dropdown>
</template>

<script>
export default {
    name: 'UserMenu',
    components: {
    },
    data() {
        return {
            list: [],
            loading: false,
            user:{},
            iconSize: 32
        }
    },
    methods: {

        routeTo: function(path) {
            let that = this;
            that.$emit("show", path);
            that.$router.push(path);
        },

        loadList: function() {
            let that = this;
            let agent_key = that.$store.getters.getAgentkey();
            let access_token = that.$store.getters.getToken();
            let postdata = {
                task: "GET_MAP_SEEDS",
                agent_key: agent_key
            };
            that.post("map", postdata)
                .then(function(data) {
                    if (data.ret == 200) {
                        that.list = data.data;
                        that.loading = true;
                    } else {
                        that.loading = false;
                    }
                }).catch(function (e) {
                    console.log(e);
                });
        }

    },
    created() {
        let that = this;
        let agent_key = that.$store.getters.getAgentkey();
        let access_token = that.$store.getters.getToken();
        let postdata = {
            'task':'GET_USER_INFO',
            agent_key: agent_key,
            access_token: access_token
        };
        that.post('user', postdata)
        .then(function(data){
            if(data.ret ==200){
                that.user=data.data;
            }
            }).catch(function(e){
                console.log(e);
            });
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.user-center {
    width: 100%;
    position: fixed; 
    top: 0px; 
    right: 0px;
    color: rgba(0, 0, 0, 0.65); 
    font-size: 14px; 
    padding-right: 4px;
}

/deep/ .ant-avatar {
    color: #fff;
}

/deep/ .ant-avatar-square {
    border-radius: 20px;
}

/deep/ .ant-dropdown-menu-dark , .ant-dropdown-menu-dark , .ant-dropdown-menu {
    /*background: #3f4250;*/
}

</style>
