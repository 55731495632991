import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const globalState = {
  tab: null,
  tablist: null,
  position: null,
  relationlist: null,
  relationitem: null,
  result: {},
  dataSource: null,
  postdata: null
}

for (let key in globalState) {
  
  //console.log(JSON.stringify(globalState));
  let itemvalue = localStorage.getItem(key); 
  
  try {
    globalState[key] = JSON.parse(localStorage.getItem(key))
  } catch (e) {
    //console.log("Exception caught:" + e); 
    //console.log(JSON.stringify(e)); 
    globalState[key] = itemvalue; 
  }
}

const global = {
  state: globalState,
  getters: {
    getTab: state => () => {
      if (state.tab == null) {
        state.tab = localStorage.getItem('tab')
      }

      //console.log("returning state.tab:" + JSON.stringify(state.tab));
      return state.tab;
    },

    getTabList: state => (key) => {
      if (state.tabList == null) {
        state.tabList = JSON.parse(localStorage.getItem('tabList'))
      }
      if (state.tabList == null) {
        state.tabList = {}
      }
      if (!state.tabList[key]) {
        state.tabList[key] = []
      }
      localStorage.setItem('tabList', JSON.stringify(state.tabList))
      return state.tabList[key]
    },
    getRelationItem: state => (move) => {
      if (!state.relationitem) {
        state.relationitem = JSON.parse(localStorage.getItem('relationitem'))
      }
      if (state.relationitem == null) {
        state.relationitem = {}
      }
      if (!state.relationitem[state.tab]) {
        state.relationitem[state.tab] = []
      }

      if (!state.position) {
        state.position = JSON.parse(localStorage.getItem('position'))
      }
      if (state.position == null) {
        state.position = {}
      }
      let i = state.position[state.tab]
      if (i == undefined) {
        state.position[state.tab] = 0
        i = 0
      }

      localStorage.setItem('position', JSON.stringify(state.position))

      if (!state.relationitem[state.tab][i]) {
        state.relationitem[state.tab][i] = {}
      }

      localStorage.setItem('relationitem', JSON.stringify(state.relationitem))
      if (move) 
        return state.relationitem[state.tab][i - move]
      else
        return state.relationitem[state.tab][i]
    },
    getPosition: state => () => {
      if (!state.position) {
        state.position = JSON.parse(localStorage.getItem('position'))
      }
      if (state.position == null) {
        state.position = {}
      }
      let i = state.position[state.tab]
      if (i == undefined) {
        state.position[state.tab] = 0
        i = 0
      }
      return i
    },
    getRelationList: state => () => {
      if (!state.relationlist) {
        state.relationlist = JSON.parse(localStorage.getItem('relationlist'))
      }
      if (state.relationlist == null) {
        state.relationlist = {}
      }
      if (!state.relationlist[state.tab]) {
        state.relationlist[state.tab] = []
      }
      localStorage.setItem('relationlist', JSON.stringify(state.relationlist))
      return state.relationlist[state.tab]
    },
    getResult: state => () => {
      if (!state.result) {
        let r = JSON.parse(localStorage.getItem('result'))
        state.result = JSON.parse(localStorage.getItem('result'))
      }

      if (state.result == null) {
        state.result = {}
      }

      let item = state.result[state.tab]
      if (item == undefined) {
        state.result[state.tab] = []
      }

      if (!state.position) {
        state.position = JSON.parse(localStorage.getItem('position'))
      }
      if (state.position == undefined) {
        state.position = {}
      }
      let i = state.position[state.tab]
      if (i == undefined) {
        state.position[state.tab] = 0
        i = 0
        state.result[state.tab][i] = []
      }

      localStorage.setItem('position', JSON.stringify(state.position))

      let result = state.result[state.tab][i]

      if (result == undefined) {
        state.result[state.tab][i] = []
      }

      localStorage.setItem('result', JSON.stringify(state.result))

      return state.result[state.tab][i]
    },
    getPostdata: state => () => {
      if (!state.postdata) {
        state.postdata = JSON.parse(localStorage.getItem('postdata'))
      }
      if (state.postdata == null) {
        state.postdata = {}
      }

      let item = state.postdata[state.tab]

      if (item == undefined) {
        state.postdata[state.tab] = []
      }

      if (!state.position) {
        state.position = JSON.parse(localStorage.getItem('position'))
      }

      let i = state.position[state.tab]
      if (i == undefined) {
        state.position[state.tab] = 0
        i = 0
        state.postdata[state.tab][i] = {}
      }

      localStorage.setItem('position', JSON.stringify(state.position))

      let postdata = state.postdata[state.tab][i]
      if (postdata == undefined) {
        state.postdata[state.tab][i] = {}
      }
      localStorage.setItem('post', JSON.stringify(state.postdata))
      return state.postdata[state.tab][i];
    },
    getDataSource: state => () => {
        state.dataSource = JSON.parse(localStorage.getItem('data_source'))
        return state.dataSource;
    }
  },
  mutations: {
    changetab(state, tab) {
      state.tab = tab;
      localStorage.setItem('tab', tab)
    },
    clear(state) {
        state.tab = null;
        localStorage.removeItem("tab")
        state.tablist = null
        localStorage.removeItem("tablist")
        state.position = null
        localStorage.removeItem("position")
        state.relationlist = null
        localStorage.removeItem("relationlist")
        state.relationitem = null
        localStorage.removeItem("relationitem")
        state.result = {}
        localStorage.removeItem("result")
        state.postdata = null
        localStorage.removeItem("postdata")
    },
    updatetablist(state, tab, list) {
      if (state.tabList == null) {
        state.tabList = JSON.parse(localStorage.getItem('tabList'))
      }
      if (state.tabList == null) {
        state.tabList = {}
      }
      state.tabList[key] = list
      localStorage.setItem('tabList', JSON.stringify(state.tabList))
      return state.tabList[key]
    },
    changeposition(state, index) {
      if (!state.position) {
        state.position = JSON.parse(localStorage.getItem('position'))
      }
      if (state.position == null) {
        state.position = {}
      }
      let i = state.position[state.tab]
      if (i == undefined) {
        state.position[state] = 0
        i = 0
      }
      state.position[state.tab] = index
      localStorage.setItem('position', JSON.stringify(state.position))
    },
    addposition(state) {
      if (!state.position) {
        state.position = JSON.parse(localStorage.getItem('position'))
      }
      if (state.position == null) {
        state.position = {}
      }
      let i = state.position[state.tab]
      if (i == undefined) {
        state.position[state] = 0
        i = 0
      }
      state.position[state.tab] = i + 1
      localStorage.setItem('position', JSON.stringify(state.position))
    },
    updateresult(state, data) {
      let result = data.result
      let tab = data.tab
      let item = state.result[tab]
      if (!item) {
        state.result[tab] = []
      }
      let i = state.position[tab]
      if (!i) {
        state.position[tab] = 0
        i = 0
        state.result[tab][i] = []
      }
      state.result[tab][i] = result
      localStorage.setItem('result', JSON.stringify(state.result))
    },
    updateresultitem(state, input) {
      let result = input.result
      let index = input.index
      if (!state.result) {
        let r = JSON.parse(localStorage.getItem('result'))
        state.result = JSON.parse(localStorage.getItem('result'))
      }

      if (state.result == null) {
        state.result = {}
      }

      let item = state.result[state.tab]
      if (item == undefined) {
        state.result[state.tab] = []
      }

      if (!state.position) {
        state.position = JSON.parse(localStorage.getItem('position'))
      }
      if (state.position == undefined) {
        state.position = {}
      }
      let i = state.position[state.tab]
      if (i == undefined) {
        state.position[state.tab] = 0
        i = 0
        state.result[state.tab][i] = []
      }

      let data = state.result[state.tab][i]

      if (data == undefined) {
        state.result[state.tab][i] = []
      }

      state.result[state.tab][i][index] = result
      localStorage.setItem('result', JSON.stringify(state.result))
    },
    updatepostdata(state, data) {
      let post = data.post
      let tab = data.tab
      let item = state.postdata[tab]
      if (!item) {
        state.postdata[tab] = []
      }
      let i = state.position[tab]
      if (!i) {
        state.position[tab] = 0
        i = 0
        state.postdata[tab][i] = {}
      }
      state.postdata[tab][i] = post
      localStorage.setItem('postdata', JSON.stringify(state.postdata))
    },
    updaterelationitem(state, item) {
      if (!state.relationitem) {
        state.relationitem = JSON.parse(localStorage.getItem('relationitem'))
      }
      if (state.relationitem == null) {
        state.relationitem = {}
      }
      if (!state.relationitem[state.tab]) {
        state.relationitem[state.tab] = []
      }

      if (!state.position) {
        state.position = JSON.parse(localStorage.getItem('position'))
      }
      if (state.position == null) {
        state.position = {}
      }
      let i = state.position[state.tab]
      if (i == undefined) {
        state.position[state.tab] = 0
        i = 0
      }

      if (!state.relationitem[state.tab][i]) {
        state.relationitem[state.tab][i] = {}
      }
      state.relationitem[state.tab][i] = item
      localStorage.setItem('relationitem', JSON.stringify(state.relationitem))
    },
    updaterelationlist(state, list) {
      if (!state.relationlist) {
        state.relationlist = JSON.parse(localStorage.getItem('relationlist'))
      }
      if (state.relationlist == null) {
        state.relationlist = {}
      }
      if (!state.relationlist[state.tab]) {
        state.relationlist[state.tab] = []
      }
      state.relationlist[state.tab] = list
      localStorage.setItem('relationlist', JSON.stringify(state.relationlist))
    },
    updateDataSource(state, dataSource) {
      if (!state.dataSource) {
        state.dataSource = JSON.parse(localStorage.getItem('data_source'))
      }
      if (state.dataSource == null) {
          state.dataSource = dataSource;
      }
      localStorage.setItem('data_source', JSON.stringify(state.dataSource))
    }
  },
  actions: {
  
  }
}

const user = {
  state: {
    username: null,
    access_token: null,
    agent_key: null,
    theme: null,
    expired_time: null,
    id: null,
    start: null, 
    login: null,
    global: null,
    uicon: null,
    utype: null,
    agent_name: null,
    agent_password: null,
    ip: null
  },
  getters: { 
    getTheme: state => () => {
      state.theme = localStorage.getItem('mode')
      return state.theme;
    },
    getDetailTheme: state => () =>{
      state.detailTheme = localStorage.getItem('detailTheme')
      return state.detailTheme;
    },
    getMode: state => () => {
      state.mode = localStorage.getItem('mode')
      return state.mode;
    },
    getColorMode: state => () => {
      state.colorMode = localStorage.getItem('colorMode')
      return state.colorMode;
    },
    getGlobal: state => () => {
      state.global = localStorage.getItem('global')
      return state.global;
    },
    getToken: state => () => {
      if (state.access_token == null) 
        state.access_token = localStorage.getItem('access_token')
      return state.access_token
    },
    getIp: state => () => {
      if (state.ip == null) 
        state.ip = localStorage.getItem('ip')
      return state.ip
    },
    getId: state => () => {
      if (state.id == null) 
        state.id = localStorage.getItem('id')
      return state.id
    },
    getAgentName: state => () => {
      if (state.agent_name == null) 
        state.agent_name = localStorage.getItem('agent_name')
      return state.agent_name
    },
    getAgentPassword: state => () => {
      if (state.agent_password == null) 
        state.agent_password = localStorage.getItem('agent_password')
      return state.agent_password
    },
    getUsername: state => () => {
      if (state.username == null) 
        state.username = localStorage.getItem('username')
      return state.username
    },
    getUsertype: state => () => {
      if (state.utype == null) 
        state.utype = localStorage.getItem('utype')
      return state.utype
    },
    getAgentkey: state => () => {
      if (state.agent_key == null)
        state.agent_key = localStorage.getItem('agent_key')
      return state.agent_key
    },
    getUIcon: state => () => {
      if (state.uicon == null) {
        state.uicon = localStorage.getItem('uicon')
      }
      return state.uicon 
    },
    getIsexpired: state => () => {
      if (state.expired_time == null) 
        state.expired_time = localStorage.getItem('expired_time')
      if (state.start == null) 
        state.start = localStorage.getItem('start')
      let now = parseInt((new Date()).valueOf()/1000);
      if (now - state.start > state.expired_time) {
        return false
      }
      return true
    },
    isLogin: state => () => {
      //if (state.login == null) {
        state.login = localStorage.getItem('login')
        let global = localStorage.getItem('global')
        if (global) {
          return true
        }

      //}
      if (state.login == false) {
          return false
      }
      //if (state.expired_time == null) 
        state.expired_time = localStorage.getItem('expired_time')
      //if (state.start == null) 
        state.start = localStorage.getItem('start')
      if (state.login && state.expired_time && state.start) {
        let now = parseInt((new Date()).valueOf()/1000);
        if (now - state.start > state.expired_time) {
          localStorage.removeItem("agent_key")
          localStorage.removeItem("access_token")
          localStorage.removeItem("login")
          state.login = false
          localStorage.clear()
          return false
        }
        return true
      } else {
        localStorage.clear()
        return false
      }
    }
  },
  mutations: {
    setTheme(state, theme) {
        state.theme = theme;
        localStorage.setItem('theme', theme)
    },
    changeDetailTheme(state,detailTheme) {
        state.detailTheme = detailTheme
        localStorage.setItem('detailTheme',detailTheme)
    },
    changeMode(state,modeValue){
        state.mode=modeValue
        localStorage.setItem('mode', modeValue)
    },
    changeColorMode(state,colorModeValue){
        state.colorMode = colorModeValue
        localStorage.setItem('colorMode',colorModeValue)
    },
    setGlobal(state, global) {
      state.global = global
      localStorage.setItem('global', global)
    },
    settoken(state, token) {
      state.access_token = token
      localStorage.setItem('access_token', token)
    },
    setid(state, id) {
      state.id = id
      localStorage.setItem('id', id)
    },
    setip(state, ip) {
      state.ip = ip
      localStorage.setItem('ip', ip)
    },
    setusername(state, username) {
      state.username = username
      localStorage.setItem('username', username)
    },
    setusertype(state, type) {
      state.utype = type
      localStorage.setItem('utype', type)
    },
    setagentkey(state, agentkey) {
      state.agent_key = agentkey
      localStorage.setItem('agent_key', agentkey)
    },
    setexpired_time(state, time) {
      state.expired_time = time
      localStorage.setItem('expired_time', time)
    },
    setstart(state, start) {
      state.start = start
      localStorage.setItem('start', start)
    },
    setlogin(state, status) {
      state.login = status
      localStorage.setItem('login', status)
    },
    setuicon(state, icon) {
      state.uicon = icon
      localStorage.setItem('uicon', icon)
    },
    setagentname(state, name) {
      state.agent_name = name
      localStorage.setItem('agent_name', name)
    },
    setagentpassword(state, password) {
      state.agent_password = password
      localStorage.setItem('agent_password', password)
    }
  },
  actions: {
  
  }
}

const store = new Vuex.Store({
  modules: {
    global: global,
    user: user
  }
})

export default store
