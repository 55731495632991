<template>
    <div :style="scatterContainer" id="scatterContent">
        <template>
        <div style="width:100%;height:14px;">
            <div class="slider">
                <a-slider :min="0.5" :max="0.9" :step="0.1" v-model="simValue" :disabled="slide" style="cursor:pointer;"/>
            </div>
        </div>
        </template>
        <div id="topicScatter" v-if="showScatter"></div>
        <div v-else ><p :style = "noData">暂无数据</p></div>
    </div>
</template>
<script>
import echarts from 'echarts'

export default {
    name: 'Charts', 
    props: {
        mapId: {
            type: String,
            default: function () {
                return ""
            }
        },

        width:{
            type: Number,
            default: function(){
                return 0;
            }
        },

        height:{
            type: Number,
            default: function(){
                return 0;
            }
        },

        trainTime:{
            type: Number,
            default: function(){
                return 0;
            }
        }

    },
    watch:{
        simValue: function(){
            this.getCluster()
        }
    },
    data() {
        return {
            echarts: echarts,
            clusterData: [],
            scatterData: [],
            scatterContainer:{width:this.width,height:this.height},
            noData:{"position":"relative","top": (this.height-80)/2+"px","left":this.width/2},
            simValue: 0.6,
            showScatter: true,
            slide: false
        }
    },
    
    created(){
        this.simValue = 0.6
        this.scatterContainer = {}
    },
    mounted() {
        var myChartContainer = document.querySelector('#topicScatter')
        let that = this
        var resizeMyChartContainer = function() {
            myChartContainer.style.height = (that.height-14)+ 'px';
            myChartContainer.style.width = that.width + 'px';
        };
        resizeMyChartContainer();
        var myChart = echarts.init(myChartContainer);
        let option = {};
        myChart.setOption(option);
        window.onresize = function () {
            resizeMyChartContainer();
            myChart.resize();
        }
        this.getCluster()
    },

    methods:{
        getCluster: function(){
            this.scatterData = []
            this.clusterData = []
            let postdata = {
                "uid" : this.mapId,
                "time_stamp" : this.trainTime,
                "task" : "GET_UPLOAD_CLUSTER",
                "sim_score": this.simValue
            }
            let that = this
            this.slide = true
            that.post("topic",postdata).then(function(res){
                if (res && res.ret == 200 && res.msg == "ok"){
                    if(res.data.length!==0){
                    that.showScatter = true
                    var scatterContent = document.querySelector("#scatterContent")
                    that.clusterData = res.data
                    var dataPositionCoor =[
                        [[5,5]],[[8.5,5]],[[5,8.5]],[[1.5,5]],[[5,1.5]],[[8.5,8.5]],[[1.5,8.5]],[[1.5,1.5]],[[8.5,1.5]],[[7.5,7.5]],[[2.5,2.5]]
                    ]
                    var dataPosition = []
                    for (var d=0;d<dataPositionCoor.length;d++){
                        var oneDimension = []
                        var positionCoor = dataPositionCoor[d][0]
                        positionCoor.push(that.clusterData[d].id)
                        oneDimension.push(positionCoor)
                        dataPosition.push(oneDimension)
                        var oneDimension = []
                    }
                    for (var i=0;i<that.clusterData.length;i++){
                        var dimension = {}
                        var offsetDic = {}
                        dimension.data = dataPosition[i]
                        dimension.type = 'scatter'
                        dimension.symbolSize = 180 - 180 / (Math.exp(0.02 * that.clusterData[i].count));
                        if(that.clusterData[i].cluster_name=="other"){
                             var param ="其他"+String(that.clusterData[i].count) 
                        }
                        else{
                             var param = String(that.clusterData[i].count)
                        }
                        dimension.emphasis = {
                                                label:{
                                                    show:true,
                                                    formatter: param,
                                                    position: 'right',
                                                    color: "#000"
                                                }
                                             };
                        that.scatterData.push(dimension)
                        var dimension = {}
                    }
                    that.drawScatter()
                    scatterContent.style.display = "block" 
                    that.slide = false
                    }
                    else{
                        that.scatterData = []
                        that.showScatter = false
                    }

                }
            })
        },

        drawScatter: function(){
            let myChart = this.echarts.init(document.getElementById("topicScatter"), "light");
            myChart.setOption({
                xAxis: {
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    show: false
                },
                yAxis: {
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    scale: true,
                    show: false
                },
                grid:{
                    top: 20,
                    left: 15
                },
                series: this.scatterData,
            });
            let that = this
            myChart.on('click', 'series.scatter', function (params) {
                var clusterId = params.data[2]
                that.$emit("changeClusterId",clusterId)
            });
        }
    }
}

 
</script>
<style scoped>

/deep/ .ant-slider{
     margin: 0px 0px 0px;
}

.noData{
    text-align: center;
}

.slider{
   width:120px;
   height:8px;
   float:right;
   margin-right:10px; 
}

/deep/.ant-slider-disabled{
    cursor:pointer;
}

/deep/ .ant-slider-disabled .ant-slider-handle, .ant-slider-disabled .ant-slider-dot{
    cursor:pointer;    
}

</style>
