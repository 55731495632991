<template>
<!-- the tag tree share setting -->
<a-row class="panel-style">
    <a-form>
        <a-row>
            <a-form-item class="item-style">
                <a-col :span="4" class="label-align"> 
                    <span>只读好友：</span>
                </a-col>
                <a-col :span="20" class="input-align">
                    <a-col :span="4">
                        <input type="checkbox" id="public" value="public" style="margin-top:2px;" v-model="readOnlyToAll">
                        <label for="public" class="lable">所有人</label>
                    </a-col>
                    <a-col :span="16">
                        <a-input 
                            v-model='readonlyFriendsCached'
                            placeholder="请输入只读好友手机号后点击 + 号">
                        </a-input>
                    </a-col>
                    <a-col :span="2" class="addPos">
                        <a-button style="height: 32px;" @click="onReadonlyChanged">
                            <a-icon type="plus"/>
                        </a-button>
                    </a-col>
                    <a-col :span="24" :key="refresh.readonly">
                        <a-tag closable @close="deleteReadonlyFriend(index)" v-for="(tag, index) in readonlyFriends" :key="index">
                            {{tag}}
                        </a-tag>
                    </a-col>
                </a-col> 
            </a-form-item>
            <a-form-item style="item-style">
                <a-col :span="4" class="label-align"> 
                    <span>可再分享好友：</span>
                </a-col>
                <a-col :span="20" class="input-align">
                    <a-col :span="4">
                        <input type="checkbox" id="public" value="public" v-model="reshareToAll">
                        <label for="public" class="lable">所有人</label>
                    </a-col>
                    <a-col :span="16">
                        <a-input 
                            v-model='reshareFriendsCached'
                            placeholder="请输入可再分享好友手机号后点击 + 号"
                        />
                    </a-col>
                    <a-col :span="2" class="addPos">
                        <a-button style="height: 32px;" @click="onReshareChanged">
                            <a-icon type="plus"/>
                        </a-button>
                    </a-col>
                    <a-col :span="24" :key="refresh.reshare">
                        <a-tag closable @close="deleteReshareFriends(index)" v-for="(tag, index) in reshareFriends" :key="index">
                            {{tag}}
                        </a-tag>
                    </a-col> 
                </a-col> 
            </a-form-item>
            <a-form-item style="item-style">
                <a-col :span="4" class="label-align"> 
                    <span>可修改好友：</span>
                </a-col>
                <a-col :span="20" class="input-align">
                    <a-col :span="4">
                        <input type="checkbox" id="public" value="public" v-model="modifyToAll">
                        <label for="public" class="lable">所有人</label>
                    </a-col>
                    <a-col :span="16">
                        <a-input 
                            v-model='modifyFriendsCached'
                            placeholder="请输入可修改好友手机号后点击 + 号"
                        />
                    </a-col>
                    <a-col :span="2" class="addPos">
                        <a-button style="height: 32px;" @click="onModifyChanged">
                            <a-icon type="plus"/>
                        </a-button>
                    </a-col>
                    <a-col :span="24" :key="refresh.modify">
                        <a-tag closable @close="deleteModifyFriend(index)" v-for="(tag, index) in modifyFriends" :key="index">
                            {{tag}}
                        </a-tag>
                    </a-col>  
                </a-col> 
            </a-form-item> 
        </a-row>
    </a-form>
    <a-button 
        type="primary" 
        @click="doneSharing"  
        style="margin-top: 4px;position:absolute;top:-49px;right:34px;">分享 
    </a-button>
    <a-button
       type="danger"
       @click="exitShare"
       style="margin-top:4px;position:absolute;top:-49px;right:110px;"
        >取消
    </a-button>
</a-row> 
</template>

<script>
export default 
{
    name  : 'ShareSetting',

    props : 
    {
        topicId:{
            type: String,
            default : function(){
                return "";
            }
        }
    },
 
    data ()
    {
        return {
            showAll: true,
            readOnlyToAll: [],
            reshareToAll: [],
            modifyToAll: [],
            share: {},
            readonlyFriends : {},
            readonlyFriendsCached : "", 
            modifyFriends: {}, 
            modifyFriendsCached: "", 
            reshareFriends: {},  
            reshareFriendsCached: "",
            refresh : {
                readonly : 1,
                modify  : 100,
                reshare  : 200,
            }
        }
    },

    components : {}, 

    watch: {
        topicId(newvalue, oldvalue) {
            this.topicId = newvalue;
            this.getTopicDetail();
        }
    },

    methods : {

        onReadonlyChanged : function () {
            if(this.readonlyFriendsCached !="")
            {
                this.addReadonlyFriends(this.readonlyFriendsCached)  
            }
        }, 

        addReadonlyFriends : function(t) {

            var uniqueFriends = []
            for (var i in this.readonlyFriends)
                uniqueFriends[this.readonlyFriends[i]] = true; 

            var newFriends = this.readonlyFriendsCached.split(/[ ,，]+/g); 
            var added = false; 
            for (var i in newFriends)
            {
                if (!uniqueFriends[newFriends[i]])
                {
                    uniqueFriends[newFriends[i]] = true; 
                    this.readonlyFriends[newFriends[i]] = newFriends[i];
                    added = true; 
                }
            }

            if (added)
            {
                this.readonlyFriendsCached = "";
                this.refresh.readonly ++;
            } 
        }, 
        
        deleteReadonlyFriend : function(index) { 
            delete this.readonlyFriends[index]; 
            this.refresh.readonly ++;
        }, 

        onModifyChanged : function () { 
            if(this.modifyFriendsCached !="")
            {
                this.addModifyFriends(this.modifyFriendsCached)  
            }
        }, 

        addModifyFriends : function(t) {
            var uniqueFriends = {}
            for (var i in this.modifyFriends)
                uniqueFriends[this.modifyFriends[i]] = true; 
            var newFriends = this.modifyFriendsCached.split(/[ ,，]+/g); 
            var added = false; 
            for (var i in newFriends)
            {
                if (!uniqueFriends[newFriends[i]])
                {
                    uniqueFriends[newFriends[i]] = true; 
                    this.modifyFriends[newFriends[i]]= newFriends[i];
                    added = true; 
                }
            }
            if (added)
            {
                this.modifyFriendsCached = "";
                this.refresh.modify ++;
            } 
        }, 
        
        deleteModifyFriend : function(index) { 
            delete this.modifyFriends[index]; 
            this.refresh.modify ++;
        }, 

        onReshareChanged : function () {
            if(this.reshareFriendsCached !="")
            {
                this.addReshareFriends(this.reshareFriendsCached)  
            } 
        }, 

        addReshareFriends : function(t) {
            var uniqueFriends = {}
            for (var i in this.reshareFriends)
                uniqueFriends[this.reshareFriends[i]] = true; 

            var newFriends = this.reshareFriendsCached.split(/[ ,，]+/g); 
            var added = false; 
            for (var i in newFriends)
            {
                if (!uniqueFriends[newFriends[i]])
                {
                    uniqueFriends[newFriends[i]] = true; 
                    this.reshareFriends[newFriends[i]] = newFriends[i];
                    added = true; 
                }
            }
            if (added)
            {
                this.reshareFriendsCached = "";
                this.refresh.reshare ++;
            }
        }, 
        
        deleteReshareFriends : function(index) {
            delete this.reshareFriends[index];
            this.refresh.reshare ++;
        },

        exitShare: function(){
            this.$emit("closeShare");
        },

        doneSharing: function(){
            var readOnlyObj = this.readonlyFriends
            if(this.readOnlyToAll.length){
                readOnlyObj.public = "public"
            }
            var reshareObj = this.reshareFriends
            if(this.reshareToAll.length){
                reshareObj.public = "public"
            }
            var modifyObj = this.modifyFriends
            if(this.modifyToAll.length){
                modifyObj.public = "public"
            }
            let share = {};
            share.readonly = readOnlyObj;
            share.reshare = reshareObj;
            share.modify = modifyObj; 
            let agentKey = this.$store.getters.getAgentkey();
            let that = this;
            let postdata = {
                task: "SHARE_TOPIC",
                "agent_key": "brand",
                topic_id : this.topicId,
                agent_key: agentKey,
                share: share 
            };
            that.post("topic", postdata)
            .then(function(data) {
                if (data.ret == 200) { 
                    that.$message.info("分享数据成功", 0.5);
                    that.$emit("closeShare");
                } else {
                    that.$message.error("分享数据出错，请重试", 0.5);
                }
            }).catch(function(e) {
                console.log(e);
                that.$message.error("分享数据出错，请重试", 0.5);
            });
        },

        getTopicDetail: function() {
            let agentKey = this.$store.getters.getAgentkey();
            let postdata = {
                "task": "GET_DETAIL_TOPIC",
                "agent_key": agentKey,
                "topic_id": this.topicId
            };
            var that = this;
            that.post('topic', postdata)
            .then(function(data) {
                if(data.ret ==200) {
                    that.share = data.data.share;
                    if (!that.share){
                        that.share = {};
                    }
                    if (!that.share.readonly){ 
                        that.share.readonly = {}; 
                    }
                    if (!that.share.modify){ 
                        that.share.modify= {}; 
                    }
                    if (!that.share.reshare){ 
                        that.share.reshare = {};
                    }
                    if(that.share.readonly && that.share.readonly.public){
                        that.readOnlyToAll = ["public"]
                        delete that.share.readonly.public
                    }
                    if(that.share.reshare && that.share.reshare.public){
                        that.reshareToAll = ["public"]
                        delete that.share.reshare.public
                    }
                    if(that.share.modify && that.share.modify.public){
                        that.modifyToAll = ["public"]
                        delete that.share.modify.public
                    }
                    that.readonlyFriends = that.share.readonly; 
                    that.reshareFriends = that.share.reshare; 
                    that.modifyFriends = that.share.modify; 
                    that.refresh.readonly ++ ; 
                    that.refresh.modify ++; 
                    that.refresh.reshare ++; 
                } else {
                    that.$message.error("获取数据出错，请重试", 0.5);
                }
            }).catch(function(e){
                console.log(e);
                that.$message.error("获取数据出错，请重试", 0.5);
            });
        }
    },

    created(){
        this.getTopicDetail();
    },
}
</script>

<style scoped>

.lable{
    margin-left: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    font-size: 14px;
}

.addPos{
    margin-left: 25px;
}
.panel-style {
    padding: 10px;
}

.item-style{
}
    
.label-align {
    text-align: right;
}

.input-align{
    padding-left: 10px;
}

</style>
