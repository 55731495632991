<template>
  <div class="theme-content">
      <div class="theme-body">
          <div style="padding: 8px; border: 1px solid #bbbbbb; border-radius: 4px;">
              <div style="padding: 4px;">
                  <span style="font-size: 16px;">设置标签地图话题</span>
              </div>
              <div style="padding: 4px;">
                  <a-input v-model="title" placeholder="标签话题" />
              </div>
              <div style="padding: 4px;">
                  <a-textarea v-model="content" placeholder="话题说明(可以为一组关键词)" autoSize />
              </div>
              <div style="padding: 4px;">
                  <a-textarea v-model="keepwords" placeholder="白名单(希望一定要显示的标签，请使用空格或换行隔开)" autoSize />
              </div>
              <div style="padding: 4px;">
                  <a-textarea v-model="stopwords" placeholder="黑名单(没必要显示的标签，请使用空格或换行隔开)" autoSize />
              </div>
              <div style="padding: 4px;">
                  <a-button type="primary" @click="addNew" block>保存</a-button>
              </div>
          </div>
          <div style="padding: 8px; margin-top: 8px; border: 1px solid #bbbbbb; border-radius: 4px; text-align: left;" 
               v-for="(item, index) in list" :key="index">
              <div>
                  <span v-if="item.status" style="float: right; margin-right: 8px;">生成时间：{{item.active_time | TimeChange}}</span>
                  <span v-else="" style="float: right; color: red; margin-right: 8px;">标签地图尚未生成,请等待1～2小时</span>
                  <h4>{{item.title}}</h4>
                  <p>{{item.content}}</p>
              </div>
              <div style="text-align: right;">
                  <a style="padding-right: 8px; cursor: pointer; color: #1890ff;" @click="editSeeds(index)">修改</a>
                  <a style="padding-right: 8px; cursor: pointer; color: red;" @click="delSeeds(item.id, index)">删除</a>
                  <a v-if="item.status" style="padding-right: 8px; cursor: pointer; color: #1890ff;" :href="'/?u=' + item.id">
                      查看标签地图
                  </a>
                  <a v-else="" style="padding-right: 8px; cursor: pointer; color: gray;">
                      查看标签地图
                  </a>
              </div>
          </div>
          <a-modal v-model="showEdit" title="修改" :footer="null" :width="clientWidth" @cancel="closeEdit">
              <div style="padding: 4px;">
                  <a-input v-model="new_title" placeholder="标签话题" />
              </div>
              <div style="padding: 4px;">
                  <a-textarea v-model="new_content" placeholder="话题说明(可以为一组关键词)" autoSize />
              </div>
              <div style="padding: 4px;">
                  <a-textarea v-model="new_keepwords" placeholder="白名单(希望一定要显示的标签，请使用空格或换行隔开)" autoSize />
              </div>
              <div style="padding: 4px;">
                  <a-textarea v-model="new_stopwords" placeholder="黑名单(没必要显示的标签，请使用空格或换行隔开)" autoSize />
              </div>
              <div style="padding: 4px;">
                  <a-button type="primary" @click="updateSeeds" block>修改</a-button>
              </div>
          </a-modal>
      </div>
  </div>
</template>

<script>
import UserMenu from '@/components/UserMenu.vue'
export default {
    name: 'UserCenter',
    components: {
      UserMenu
    },
    data () {
        return {
            title: "",
            content: "",
            clientWidth: 500,
            autoWidth: {"width": "520px"},
            stopwords: "",
            keepwords: "",
            new_title: "",
            new_content: "",
            new_stopwords: "",
            new_keepwords: "",
            new_id: "",
            index: -1,
            list: [],
            loading: true,
            noSeeds: false,
            showEdit: false
        }
    },
    filters: {
        TimeChange(value) {
           let date = new Date(value);
           let y = date.getFullYear();
           let MM = date.getMonth() + 1;
           MM = MM < 10 ? "0" + MM : MM;
           let d = date.getDate();
           d = d < 10 ? "0" + d : d;
           let h = date.getHours();
           h = h < 10 ? "0" + h : h;
           let m = date.getMinutes();
           m = m < 10 ? "0" + m : m;
           let s = date.getSeconds();
           s = s < 10 ? "0" + s : s;
           return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
        }
    },
    methods: {

        closeEdit: function() {
            this.showEdit = false;
        },

        editSeeds: function(index) {
            this.showEdit = true;
            let Seeds = this.list[index];
            this.new_title = Seeds.title;
            this.new_content = Seeds.content;
            this.new_stopwords = Seeds.stopwords;
            this.new_keepwords = Seeds.keepwords;
            this.new_id = Seeds.id;
            this.index = index;
        },

        updateSeeds: function () {
            this.new_title = this.new_title.trim();
            this.new_content = this.new_content.trim();
            if (this.new_title.length == 0 || this.new_content.length == 0) {
                return;
            }
            let that = this;
            let postdata = {
                task: "UPDATE_MAP_SEEDS",
                title: that.new_title,
                content: that.new_content,
                stopwords: that.new_stopwords,
                keepwords: that.new_keepwords,
                id: that.new_id
            };
            that.post("map", postdata)
                .then(function(data) {
                    if (data.ret == 200) {
                        that.list[that.index] = {
                            id: data.data.id,
                            title: data.data.title,
                            content: data.data.content,
                            stopwords: data.data.stopwords,
                            keepwords: data.data.keepwords
                        }
                        that.showEdit = false;
                        that.refresh += 1;
                    } else {
                        that.$message.info("修改失败");
                    }
                }).catch(function (e) {
                    that.$message.info("修改失败");
                });

        },

        delSeeds: function(id, index) {
            let that = this;
            let postdata = {
                id: id,
                task: "DEL_MAP_SEEDS"
            };
            that.post("map", postdata)
                .then(function(data) {
                    if (data.ret == 200) {
                        let tmp = that.list;
                        let newList = [];
                        for (let i = 0; i < tmp.length; i++) {
                            if (i != index) {
                                newList.push(tmp[i]);
                            }
                        }
                        that.list = newList;
                    } else {
                        that.$message.info("删除失败");
                    }
                }).catch(function (e) {
                    that.$message.info("删除失败");
                    console.log(e);
                });
        },

        addNew: function() {
            this.title = this.title.trim();
            this.content = this.content.trim();
            this.stopwords = this.stopwords.trim();
            this.keepwords = this.keepwords.trim();
            if (this.title.length == 0 || this.content.length == 0) {
                this.$message.info("请输入标题和关键词");
                return;
            }
            let that = this;
            let agent_key = that.$store.getters.getAgentkey();
            let postdata = {
                task: "ADD_MAP_SEEDS",
                title: that.title,
                content: that.content,
                stopwords: that.stopwords,
                keepwords: that.keepwords,
                agent_key: agent_key
            };
            that.post("map", postdata)
                .then(function(data) {
                    if (data.ret == 200) {
                        that.list.push(data.data);
                        that.title = "";
                        that.content = "";
                        that.keepwords = "";
                        that.stopwords = "";
                    } else {
                        that.loading = false;
                        that.noArticle = true;
                    }
                }).catch(function (e) {
                    console.log(e);
                });
        },

        logout: function () {
            this.$store.commit('clear');
            this.$store.commit('setlogin', false);
            this.$router.push("/");
        }
    },
    created() {
        let that = this;
        let agent_key = that.$store.getters.getAgentkey();
        let postdata = {
            task: "GET_MAP_SEEDS",
            agent_key: agent_key
        };
        let clientWidth = document.body.clientWidth;
        if (clientWidth > 600) {
            clientWidth = 600;
            this.autoWidth = {"width": (clientWidth + 16) + "px"};
        } else {
            this.autoWidth = {"width": (clientWidth - 12) + "px"};
        }
        that.post("map", postdata)
            .then(function(data) {
                if (data.ret == 200) {
                    that.list = data.data;
                } else {
                    that.loading = false;
                    that.noArticle = true;
                }
            }).catch(function (e) {
                console.log(e);
            });

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.theme-content {
    background-color: white; 
    width: 100%;
    overflow: hidden;
    margin-left: auto; 
    margin-right: auto;
}

.theme-body {
    padding: 8px; 
    background-color: white; 
    margin-top: 8px; 
    height: 500px;
    overflow: scroll;
    margin-left: auto; 
    margin-right: auto; 
    z-index: 1;
}

.user-menu {
    position: fixed; 
    top: 20px; 
    right: 40px; 
    z-index: 3
}

/deep/ .ant-avatar-square {
    border-radius: 20px;
}

</style>
